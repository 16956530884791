import logo from 'assets/logo-light-1.svg'
import logo2 from 'assets/logo-light-2.svg'
import logoSquare from 'assets/logo-square.svg'
import cn from 'classnames'
import Icon from 'components/Icon'
import Link from 'components/Link'
import * as routes from 'lib/routes'
import useDropdown from 'lib/useDropdown'
import useLogout from 'lib/useLogout'
import useQuery from 'lib/useQuery'
import { useRef, useState } from 'react'
import {
  canGenDoc,
  canManageProperties,
  canSyncSheets,
  canViewUsers,
  canViewLastSignUpsAndTxs,
  canSyncTbankrot,
} from '../../lib/can.js'
import css from './styles.module.scss'

const DashboardLayout = ({ children }) => {
  const { me } = useQuery('getMe')
  const [isDropdownProfileOpen, setDropdownProfileOpen] = useState(false)
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const profileRef = useRef()
  const dropdownProfileRef = useRef()
  const menuRef = useRef()
  const dropdownMenuRef = useRef()
  useDropdown(profileRef, dropdownProfileRef, isDropdownProfileOpen, setDropdownProfileOpen)
  useDropdown(menuRef, dropdownMenuRef, isMobileMenuOpen, setMobileMenuOpen)
  const logout = useLogout()
  return (
    <div className={css.dashboardLayout}>
      <div ref={dropdownMenuRef} className={cn({ [css.overlay]: true, [css.hidden]: !isMobileMenuOpen })} />
      <div ref={dropdownMenuRef} className={cn({ [css.mobileMenu]: true, [css.hidden]: !isMobileMenuOpen })}>
        {me ? (
          <div className={cn(css.links, css.compressed)}>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#summary'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-case" />
                </div>
                <div className={css.text}>Баланс</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#txs'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-calc" />
                </div>
                <div className={css.text}>История операций</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#future'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-building" />
                </div>
                <div className={css.text}>Доступные инвест. предложения</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#portfolio'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-case" />
                </div>
                <div className={css.text}>Инвест, портфель</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#finished'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-building" />
                </div>
                <div className={css.text}>Завершённые объекты</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getAllPropertiesRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-building" />
                </div>
                <div className={css.text}>Все объекты</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getNewsRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="bell" />
                </div>
                <div className={css.text}>Новости</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#referral'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-referral" />
                </div>
                <div className={css.text}>Реферальная система</div>
              </Link>
            </div>
            {/* <div className={css.item}>
            <Link
              onClick={() => setMobileMenuOpen(false)}
              className={css.link}
              currentClassName={css.current}
              to={routes.getDashboardRoute() + '#calc'}
            >
              <div className={css.iconPlace}>
                <Icon name="calc" />
              </div>
              <div className={css.text}>Калькулятор</div>
            </Link>
          </div> */}
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getDashboardRoute() + '#contract'}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-docs" />
                </div>
                <div className={css.text}>Документы</div>
              </Link>
            </div>
            {/* <div className={css.item}>
            <Link
              onClick={() => setMobileMenuOpen(false)}
              className={css.link}
              currentClassName={css.current}
              to={routes.getDashboardRoute() + '#help'}
            >
              <div className={css.iconPlace}>
                <Icon name="new-question" />
              </div>
              <div className={css.text}>Вопросы и ответы</div>
            </Link>
          </div> */}
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getSetupBotRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="telegram" width="20" height="20" />
                </div>
                <div className={css.text}>Телеграм бот</div>
              </Link>
            </div>
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getFaqRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-question" />
                </div>
                <div className={css.text}>Вопросы и ответы</div>
              </Link>
            </div>
            {canSyncSheets(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getSyncSheetsRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="sync" />
                  </div>
                  <div className={css.text}>Синхронизация гугл</div>
                </Link>
              </div>
            )}
            {canSyncTbankrot(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getSyncTbankrotRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="sync" />
                  </div>
                  <div className={css.text}>Синхронизация тбанкрот</div>
                </Link>
              </div>
            )}
            {canManageProperties(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getPropertiesRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="property" />
                  </div>
                  <div className={css.text}>Управление объектами</div>
                </Link>
              </div>
            )}
            {canManageProperties(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getCompanyResultsAdminRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="property" />
                  </div>
                  <div className={css.text}>Управление результатами</div>
                </Link>
              </div>
            )}
            {canViewUsers(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getUsersRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="team" />
                  </div>
                  <div className={css.text}>Пользователи</div>
                </Link>
              </div>
            )}
            {/* canManageUserPassports(me) && (
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getPassportsRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="passport" />
                </div>
                <div className={css.text}>Паспорта</div>
              </Link>
            </div>
          ) */}
            {/* canManageUserBankAccounts(me) && (
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getBankAccountsRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="bank" />
                </div>
                <div className={css.text}>Реквизиты</div>
              </Link>
            </div>
          ) */}
            <div className={css.delimiter} />
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getEditProfileRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-lock" />
                </div>
                <div className={css.text}>Смена пароля</div>
              </Link>
            </div>
            <div className={css.delimiter} />
            {/* canUseFutureFeatures(me) && (
            <div className={css.item}>
              <Link
                onClick={() => setMobileMenuOpen(false)}
                className={css.link}
                currentClassName={css.current}
                to={routes.getReferralsRoute()}
              >
                <div className={css.iconPlace}>
                  <Icon name="structure" />
                </div>
                <div className={css.text}>Реферальная программа</div>
              </Link>
            </div>
          ) */}
            {canGenDoc(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getGenDocRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="document" />
                  </div>
                  <div className={css.text}>Генерация документов</div>
                </Link>
              </div>
            )}
            {canViewLastSignUpsAndTxs(me) && (
              <div className={css.item}>
                <Link
                  onClick={() => setMobileMenuOpen(false)}
                  className={css.link}
                  currentClassName={css.current}
                  to={routes.getLastSignUpsAndTxsRoute()}
                >
                  <div className={css.iconPlace}>
                    <Icon name="new-question" />
                  </div>
                  <div className={css.text}>Последние пользователи и транзакции</div>
                </Link>
              </div>
            )}
            <div className={css.delimiter} />
            <div className={css.item}>
              <Link
                className={cn(css.link, css.red)}
                onClick={() => {
                  setMobileMenuOpen(false)
                  logout()
                }}
              >
                <div className={css.iconPlace}>
                  <Icon name="new-logout" />
                </div>
                <div className={css.text}>Выйти</div>
              </Link>
            </div>
          </div>
        ) : (
          <div className={cn(css.links, css.compressed)}>
            <div className={css.item}>
              <Link className={cn(css.link)} to={routes.getSignInRoute()}>
                <div className={css.iconPlace}>
                  <Icon name="signin" />
                </div>
                <div className={css.text}>Вход</div>
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className={css.header}>
        <div className={css.container}>
          <div className={css.menuButtonPlace}>
            <Link
              className={css.menuButton}
              href="#"
              ref={menuRef}
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              <Icon name="burger" />
            </Link>
          </div>
          <div className={css.logoPlace}>
            <Link
              className={css.logo}
              to={me ? routes.getDashboardRoute() : routes.getHomeRoute()}
              currentClassName={css.current}
            >
              <img width={24} height={24} className={css.logoSquare} src={logoSquare} alt="" />
              <span className={css.logoName}>{me?.name?.split(' ')[1] || 'Estate Invest'}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={css.cols}>
        {me ? (
          <div className={css.left}>
            <div className={css.container}>
              <div className={css.topAndBottom}>
                <div className={css.top}>
                  <div className={css.leftColHeader}>
                    <Link
                      className={css.logo}
                      to={me ? routes.getDashboardRoute() + '#summary' : routes.getHomeRoute()}
                      currentClassName={css.current}
                    >
                      <img width={40} height={40} className={css.logoSquare} src={logoSquare} alt="" />
                      <span className={css.logoName}>{me?.name?.split(' ')[1] || 'Estate Invest'}</span>
                    </Link>
                  </div>
                  <div className={css.links}>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#summary'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-case" />
                        </div>
                        <div className={css.text}>Баланс</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#txs'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-calc" />
                        </div>
                        <div className={css.text}>История операций</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#future'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-building" />
                        </div>
                        <div className={css.text}>Доступные инвест. предложения</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#portfolio'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-case" />
                        </div>
                        <div className={css.text}>Инвест. портфель</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#finished'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-building" />
                        </div>
                        <div className={css.text}>Завершённые объекты</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} currentClassName={css.current} to={routes.getAllPropertiesRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="new-building" />
                        </div>
                        <div className={css.text}>Все объекты</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} currentClassName={css.current} to={routes.getNewsRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="bell" />
                        </div>
                        <div className={css.text}>Новости</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#referral'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-referral" />
                        </div>
                        <div className={css.text}>Реферальная система</div>
                      </Link>
                    </div>
                    {/* <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#calc'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="calc" />
                        </div>
                        <div className={css.text}>Калькулятор</div>
                      </Link>
                    </div> */}
                    <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#contract'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-docs" />
                        </div>
                        <div className={css.text}>Документы</div>
                      </Link>
                    </div>
                    {/* <div className={css.item}>
                      <Link
                        className={css.link}
                        currentClassName={css.current}
                        to={routes.getDashboardRoute() + '#help'}
                      >
                        <div className={css.iconPlace}>
                          <Icon name="new-question" />
                        </div>
                        <div className={css.text}>Помощь</div>
                      </Link>
                    </div> */}
                    <div className={css.item}>
                      <Link className={css.link} currentClassName={css.current} to={routes.getSetupBotRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="telegram" width="20" height="20" />
                        </div>
                        <div className={css.text}>Телеграм бот</div>
                      </Link>
                    </div>
                    <div className={css.item}>
                      <Link className={css.link} currentClassName={css.current} to={routes.getFaqRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="new-question" />
                        </div>
                        <div className={css.text}>Вопросы и ответы</div>
                      </Link>
                    </div>
                    {canSyncSheets(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getSyncSheetsRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="sync" />
                          </div>
                          <div className={css.text}>Синхронизация гугл</div>
                        </Link>
                      </div>
                    )}
                    {canSyncTbankrot(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getSyncTbankrotRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="sync" />
                          </div>
                          <div className={css.text}>Синхронизация тбанкрот</div>
                        </Link>
                      </div>
                    )}
                    {canManageProperties(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getPropertiesRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="property" />
                          </div>
                          <div className={css.text}>Управление объектами</div>
                        </Link>
                      </div>
                    )}
                    {canManageProperties(me) && (
                      <div className={css.item}>
                        <Link
                          className={css.link}
                          currentClassName={css.current}
                          to={routes.getCompanyResultsAdminRoute()}
                        >
                          <div className={css.iconPlace}>
                            <Icon name="property" />
                          </div>
                          <div className={css.text}>Управление результатами</div>
                        </Link>
                      </div>
                    )}
                    <div className={css.item}>
                      <Link className={css.link} currentClassName={css.current} to={routes.getEditProfileRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="new-lock" />
                        </div>
                        <div className={css.text}>Смена пароля</div>
                      </Link>
                    </div>
                    {/* canUseFutureFeatures(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getReferralsRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="structure" />
                          </div>
                          <div className={css.text}>Реферальная программа</div>
                        </Link>
                      </div>
                    ) */}
                    {canGenDoc(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getGenDocRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="document" />
                          </div>
                          <div className={css.text}>Генерация документов</div>
                        </Link>
                      </div>
                    )}
                    {canViewUsers(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getUsersRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="team" />
                          </div>
                          <div className={css.text}>Пользователи</div>
                        </Link>
                      </div>
                    )}
                    {canViewLastSignUpsAndTxs(me) && (
                      <div className={css.item}>
                        <Link
                          className={css.link}
                          currentClassName={css.current}
                          to={routes.getLastSignUpsAndTxsRoute()}
                        >
                          <div className={css.iconPlace}>
                            <Icon name="new-question" />
                          </div>
                          <div className={css.text}>Последние пользователи и транзакции</div>
                        </Link>
                      </div>
                    )}
                    {/* canManageUserPassports(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getPassportsRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="passport" />
                          </div>
                          <div className={css.text}>Паспорта</div>
                        </Link>
                      </div>
                    ) */}
                    {/* canManageUserBankAccounts(me) && (
                      <div className={css.item}>
                        <Link className={css.link} currentClassName={css.current} to={routes.getBankAccountsRoute()}>
                          <div className={css.iconPlace}>
                            <Icon name="bank" />
                          </div>
                          <div className={css.text}>Реквизиты</div>
                        </Link>
                      </div>
                    ) */}
                  </div>
                </div>
                <div className={css.bottom}>
                  <div className={cn(css.links)}>
                    <div className={css.delimiter} />
                    <div className={css.item}>
                      <Link className={cn(css.link, css.red)} onClick={logout}>
                        <div className={css.iconPlace}>
                          <Icon name="new-logout" />
                        </div>
                        <div className={css.text}>Выйти</div>
                      </Link>
                    </div>
                  </div>
                  {/* <div className={css.links}>
                    {me && (
                      <div className={css.item}>
                        <Link
                          className={css.link}
                          currentClassName={css.current}
                          onClick={(e) => e.preventDefault()}
                          ref={profileRef}
                          href="#"
                        >
                          <div className={css.iconPlace}>
                            <Icon name="profile" />
                          </div>
                          <div className={css.text}>{me.displayName}</div>
                        </Link>
                        <div
                          className={cn({
                            [css.dropdown]: true,
                            [css.toTop]: true,
                            [css.hidden]: !isDropdownProfileOpen,
                          })}
                          ref={dropdownProfileRef}
                        >
                          <div className={cn(css.links, css.compressed)}>
                            <div className={css.item}>
                              <Link
                                className={css.link}
                                currentClassName={css.current}
                                to={routes.getEditProfileRoute()}
                              >
                                <div className={css.iconPlace}>
                                  <Icon name="profile" />
                                </div>
                                <div className={css.text}>Мой профиль</div>
                              </Link>
                            </div>
                            <div className={css.item}>
                              <Link className={css.link} currentClassName={css.current} to={routes.getReferralsRoute()}>
                                <div className={css.iconPlace}>
                                  <Icon name="structure" />
                                </div>
                                <div className={css.text}>Реферальная программа</div>
                              </Link>
                            </div>
                            <div className={css.delimiter} />
                            <div className={css.item}>
                              <Link className={css.link} onClick={logout}>
                                <div className={css.iconPlace}>
                                  <Icon name="logout" />
                                </div>
                                <div className={css.text}>Выйти</div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                        </div>*/}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={css.left}>
            <div className={css.container}>
              <div className={css.topAndBottom}>
                <div className={css.top}>
                  <div className={css.leftColHeader}>
                    <Link
                      className={css.logo}
                      to={me ? routes.getDashboardRoute() + '#summary' : routes.getHomeRoute()}
                      currentClassName={css.current}
                    >
                      <img width={40} height={40} className={css.logoSquare} src={logoSquare} alt="" />
                      <span className={css.logoName}>{me?.name?.split(' ')[1] || 'Estate Invest'}</span>
                    </Link>
                  </div>
                </div>
                <div className={css.bottom}>
                  <div className={cn(css.links)}>
                    <div className={css.delimiter} />
                    <div className={css.item}>
                      {/* <Link className={cn(css.link, css.red)} to={routes.getHomeRoute()}>
                      <div className={css.iconPlace}>
                        <Icon name="signin" />
                      </div>
                      <div className={css.text}>Главная</div>
                    </Link> */}
                      <Link className={cn(css.link)} to={routes.getSignInRoute()}>
                        <div className={css.iconPlace}>
                          <Icon name="signin" />
                        </div>
                        <div className={css.text}>Вход</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={css.right} id="scroll-wrapper">
          {children}
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
