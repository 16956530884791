import { ReactComponent as LogoBig } from 'assets/landing/logo-big.svg'
import { ReactComponent as LogoGray } from 'assets/landing/logo-gray.svg'
import { ReactComponent as LogoSmall } from 'assets/landing/logo-small.svg'
import { ReactComponent as Risk1 } from 'assets/landing/risk-1-icon.svg'
import { ReactComponent as Risk2 } from 'assets/landing/risk-2-icon.svg'
import { ReactComponent as Risk3 } from 'assets/landing/risk-3-icon.svg'
import { ReactComponent as Risk4 } from 'assets/landing/risk-4-icon.svg'
import cn from 'classnames'
import Alert from 'components/Alert'
import Button1 from 'components/Button1'
import Form, { FormItems, useForm } from 'components/Form'
import Icon from 'components/Icon'
import Input from 'components/Input'
import PageWrapper from 'components/PageWrapper'
import getUploadUrl from 'lib/getUploadUrl'
import parseLocationSearch from 'lib/parseLocationSearch'
import {
  getCompanyResultsRoute,
  getDashboardRoute,
  getDocsContractRoute,
  getDocsPolicyRoute,
  getDocsTermsRoute,
  getSignInRoute,
} from 'lib/routes'
import toHumanDate from 'lib/toHumanDate'
import toMoney from 'lib/toMoney'
import useMutation from 'lib/useMutation'
import useQuery from 'lib/useQuery'
import yup from 'lib/validation'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import css from './styles.module.scss'
import _ from 'lodash'
import ScrollToHash, { scrollToHash } from 'components/ScrollToHash'
import { useApolloClient } from '@apollo/client'
import Cookies from 'js-cookie'
import moment from 'moment'
import { AlfaAuthButton } from 'components/Alfa/AlfaAuthButton'

const MainForm = ({ referralName, referralCode }) => {
  const [sendFormToTelegram] = useMutation('sendFormToTelegram')
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  // get from search params sb_id
  const sbId = new URLSearchParams(window.location.search).get('sb_id') || ''
  const history = useHistory()
  const apolloClient = useApolloClient()
  const { formik, buttonProps, alertProps, success } = useForm({
    forgetSuccessDelay: 0,
    initialValues: {
      fio: '',
      phone: '',
      telegram: '',
      email: '',
      referralCode,
      referralName,
    },
    resetOnSuccess: true,
    onSubmit: ({ fio, phone, telegram, email, referralCode }) => {
      return sendFormToTelegram({
        variables: { input: { fio, phone, telegram, email, referralCode, sbId } },
      }).then(({ data }) => {
        const { email, password, token } = data.sendFormToTelegram
        if (token) {
          Cookies.set('token', token, { expires: 99999 })
          return apolloClient.resetStore().then(() => {
            history.push(getDashboardRoute() + `?modal=welcome&password=${password}&email=${email}`)
            return false
          })
        } else {
          return { duplicate: true }
        }
      })
    },
    validationSchema: yup.object({
      fio: yup
        .string()
        .required()
        .test(
          'full-fio',
          'Пожалуйста, укажите ФИО полностью',
          (value) =>
            (value || '')
              .replace(/ +(?= )/g, '')
              .trim()
              .split(' ').length >= 2
        ),
      phone: yup.string().required(),
      telegram: yup.string().required(),
      email: yup.string().email().required(),
    }),
    successMessage: (result) =>
      result?.duplicate
        ? 'Вы были зарегистрированы ранее под этим адресом электронной почты. Пожалуйста, войдите в свой личный кабинет по своей почте на странице входа.'
        : 'Анкета успешно отправлена. Мы скоро с вами свяжемся. Спасибо!',
  })
  return (
    <Form formik={formik}>
      <FormItems>
        <AlfaAuthButton buttonText="Зарегистрироваться через Альфа-Банк" pText="Зарегистрируйтесь через Альфа-Банк" />
        <Input icon="person1" fullWidth name="fio" placeholder="Ваше ФИО" formik={formik} />
        <Input icon="mail1" fullWidth placeholder="Электронная почта" name="email" formik={formik} />
        <Input icon="phone1" fullWidth placeholder="Номер телефона" name="phone" formik={formik} />
        <Input
          icon="telegram1"
          fullWidth
          name="telegram"
          placeholder="Ваш телеграм"
          hint="@никнейм или номер телефона"
          formik={formik}
        />
        <Alert {...alertProps} />
        <Button1 fullWidth {...buttonProps}>
          Зарегистрироваться
        </Button1>
      </FormItems>
    </Form>
  )
}

const Property = ({ property, index, setItemsOffsets, parentOffset, scrollLeft, scrollWidth, clientWidth }) => {
  const elRef = useRef(null)
  useEffect(() => {
    if (elRef.current) {
      setItemsOffsets((itemsOffsets) => {
        const newItemsOffsets = [...itemsOffsets]
        newItemsOffsets[index] = elRef.current.getBoundingClientRect().left - parentOffset
        return newItemsOffsets
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentOffset, scrollLeft, scrollWidth, clientWidth, elRef])
  return (
    <div ref={elRef} className={css.itemOuter}>
      <div className={css.item}>
        <div className={css.itemPicPlace}>
          {property.startsAt && (
            <p className={css.itemDate}>
              до {toHumanDate(moment(property.fundraisingUntil).add(10, 'hour'), 'D MMMM').toLowerCase()}
            </p>
          )}
          <img className={css.itemPic} src={getUploadUrl(property.photo, 'property')} alt="" />
        </div>
        <div className={css.itemContent}>
          <div className={css.itemTitle}>
            #{property.serialNumber} {property.name}
          </div>
          <div className={css.stats}>
            <div className={css.stat}>{property.roi}</div>
            <div className={css.stat}>
              {property.minPeriod}-{property.maxPeriod} мес
            </div>
            <div className={css.stat}>
              <Icon className={css.statIcon} name="persons" />
              {property.investorsCount}
            </div>
          </div>
          <div className={css.progress}>
            <div
              className={css.filled}
              style={{
                width: `${(
                  ((property.neededToCollect - property.leftToCollectOriginal) / property.neededToCollectOriginal) *
                  100
                ).toFixed(2)}%`,
              }}
            />
            <div
              className={css.empty}
              style={{
                width: `${((property.leftToCollectOriginal / property.neededToCollectOriginal) * 100).toFixed(2)}%`,
              }}
            />
          </div>
          <div className={css.money}>
            <div className={css.neededToCollect}>
              {toMoney(property.neededToCollectOriginal, property.collectCurrency)}
            </div>
            <div className={css.leftToCellect}>
              остаток {toMoney(property.leftToCollectOriginal, property.collectCurrency)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Properties = ({ properties }) => {
  const scrollableElRef = useRef(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)
  const [leftArrowVisible, setLeftArrowVisible] = useState(false)
  const [rightArrowVisible, setRightArrowVisible] = useState(false)
  const [parentOffset, setParentOffset] = useState(0)
  const [itemsOffsets, setItemsOffsets] = useState([0, 0, 0, 0, 0])
  const updateScrollData = () => {
    setScrollLeft(scrollableElRef.current?.scrollLeft || 0)
    setScrollWidth(scrollableElRef.current?.scrollWidth || 0)
    setClientWidth(scrollableElRef.current?.clientWidth || 0)
    setParentOffset(scrollableElRef.current?.getBoundingClientRect().left || 0)
  }
  useEffect(() => {
    updateScrollData()
    const scrollableElRefCurrent = scrollableElRef.current
    scrollableElRefCurrent?.addEventListener('scroll', updateScrollData)
    return () => {
      scrollableElRefCurrent?.removeEventListener('scroll', updateScrollData)
    }
  }, [scrollableElRef])
  useLayoutEffect(() => {
    window.addEventListener('resize', updateScrollData)
    return () => window.removeEventListener('resize', updateScrollData)
  }, [])
  useEffect(() => {
    setLeftArrowVisible(scrollLeft > 0)
    setRightArrowVisible(scrollLeft + clientWidth < scrollWidth)
  }, [scrollLeft, scrollWidth, clientWidth])

  return (
    <div id="properties" className={cn(css.section, css.properties)}>
      <div className={css.wrapper}>
        <h1 className={css.sectionTitle}>Доступно для инвестирования</h1>
        <p className={css.sectionDesc}>Каждый месяц наша команда выбирает 3-8 объекта, самые прибыльные</p>
        <div className={css.itemsOuter}>
          <div
            className={cn({ [css.leftArrowWrapper]: true, [css.arrowWrapper]: true, [css.visible]: leftArrowVisible })}
            onClick={() => {
              const nextItemIndex = _.findLastIndex(itemsOffsets, (offset) => offset < 0)
              if (nextItemIndex === -1) {
                return
              }
              const nextItemOffset = itemsOffsets[nextItemIndex]
              scrollableElRef.current.scrollTo({
                left: scrollLeft + nextItemOffset - 10,
                behavior: 'smooth',
              })
            }}
          >
            <div className={css.round}>
              <Icon name="slider-left-arrow" className={css.icon} />
            </div>
          </div>
          <div
            className={cn({
              [css.rightArrowWrapper]: true,
              [css.arrowWrapper]: true,
              [css.visible]: rightArrowVisible,
            })}
            onClick={() => {
              const nextItemIndex = itemsOffsets.findIndex((offset) => offset > 20)
              if (nextItemIndex === -1) {
                return
              }
              const nextItemOffset = itemsOffsets[nextItemIndex]
              scrollableElRef.current.scrollTo({
                left: scrollLeft + nextItemOffset - 10,
                behavior: 'smooth',
              })
            }}
          >
            <div className={css.round}>
              <Icon name="slider-right-arrow" className={css.icon} />
            </div>
          </div>
          <div className={cn(css.scrollable, css.items)} ref={scrollableElRef}>
            <div className={css.itemsInner}>
              {properties.map((property, i) => (
                <Property
                  setItemsOffsets={setItemsOffsets}
                  parentOffset={parentOffset}
                  scrollLeft={scrollLeft}
                  scrollWidth={scrollWidth}
                  clientWidth={clientWidth}
                  key={i}
                  index={i}
                  property={property}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PromoPage = ({ referralName, referralCode, properties }) => {
  const { me, loading } = useQuery('getMe')
  const { pathname } = useLocation()
  return (
    <div className={css.page}>
      <div id="header" className={cn(css.section, css.header)}>
        <div className={css.headerInner}>
          <div className={css.wrapper}>
            <div className={css.logoPlace}>
              <Link to={'#header'} className={css.logoBigLink}>
                <LogoBig />
              </Link>
              <Link to={'#header'} className={css.logoSmallLink}>
                <LogoSmall />
              </Link>
            </div>
            <div className={css.authMenu}>
              {loading ? null : !me ? (
                <>
                  <div className={css.authMenuItem}>
                    <Button1
                      icon="signin"
                      iconClassName={css.authMenuItemIcon}
                      size="small"
                      color="blue-without-background"
                      to={pathname === '/new' ? getSignInRoute() + '?alfa=1' : getSignInRoute()}
                    >
                      Вход
                    </Button1>
                  </div>
                  <div className={css.authMenuItem}>
                    <Button1 size="small" color="blue" onClick={() => scrollToHash('#form')}>
                      Регистрация
                    </Button1>
                  </div>
                </>
              ) : (
                <div className={css.authMenuItem}>
                  <Button1
                    icon="dashboard"
                    iconClassName={css.authMenuItemIcon}
                    size="small"
                    color="blue-without-background"
                    to={getDashboardRoute()}
                  >
                    Личный кабинет
                  </Button1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!referralName && (
        <div className={cn(css.section, css.ref)}>
          <div className={css.wrapper}>
            <div className={css.panel}>
              👋 <b>{referralName}</b> приглашает вас в Estate Invest
            </div>
          </div>
        </div>
      )}
      <div className={cn(css.section, css.intro)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Железобетонные инвестиции</h1>
          <p className={css.sectionDesc}>
            Доходность выше, чем у депозитов и облигаций. <br className={css.break} />
            Более стабильно, чем при вложении в акции
          </p>
          <div className={css.buttonPlace}>
            <Button1 color="orange" size="normal" onClick={() => scrollToHash('#form')}>
              Зарегистрироваться
            </Button1>
          </div>
          <div className={css.picPlace}>
            <img className={css.pic} src="/images/landing/header-pic.jpg" alt="" width="800" height="418" />
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.directions)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Инвестиционные направления</h1>
          <p className={css.sectionDesc}>
            Мы работаем с объектами недвижимости, в которых всегда заложена прибыль. Даже если рынок временно падает,
            в долгосрочной перспективе цены на недвижимость растут, что гарантировано приносит доход.
          </p>
          <div className={css.items}>
            {[
              {
                title: (
                  <>
                    Квартиры{' '}
                    <span>
                      <br />
                    </span>
                    и дома
                  </>
                ),
                desc: 'Спрос на жилую недвижимость есть всегда, что дает стабильную, прогнозируемую доходность. При растущей инфляции жилые объекты не только позволяют сохранить капитал, но получить сверхдоход на вложенные средства.',
                picSrc: '/images/landing/dir-1-pic.jpg',
              },
              {
                title: (
                  <>
                    Коммерческие{' '}
                    <span>
                      <br />
                    </span>
                    помещения
                  </>
                ),
                desc: 'Помещения, предназначенные для коммерческого использования, такого как магазины, офисы, рестораны, могут приносить значительно большую прибыль благодаря разнообразию стратегий работы с ними.',
                picSrc: '/images/landing/dir-2-pic.jpg',
              },
              {
                title: (
                  <>
                    Земельные{' '}
                    <span>
                      <br />
                    </span>
                    участки
                  </>
                ),
                desc: 'Земля никогда не теряет своей ценности. Ее количество не увеличивается, но есть широкий потенциал для различных стратегий использования. Например, покупка большого участка и его деление на более мелкие части может принести до 800% прибыли.',
                picSrc: '/images/landing/dir-3-pic.jpg',
              },
              // {
              //   title: 'Автомобильное направление',
              //   desc: 'Короткий цикл сделки позволяет реализовывать 3-4 авто за месяц, что быстро высвобождает капитал и даёт возможность вкладывать его заново, работая по принципу сложного процента.',
              //   picSrc: '/images/landing/dir-4-pic.jpg',
              // },
            ].map(({ title, desc, picSrc }, i) => (
              <div key={i} className={css.item}>
                <div className={css.itemInner}>
                  <div className={css.itemPicPlace}>
                    <img src={picSrc} className={css.itemPic} alt="" width="240" height="240" />
                  </div>
                  <div className={css.itemTitle}>{title}</div>
                  <div className={css.itemDesc}>{desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Properties properties={properties} />
      <div className={cn(css.section, css.dynamic)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Динамика портфеля Estate Invest</h1>
          <p className={css.sectionDesc}>
            В сравнении с акциями, которые подвержены геополитическому риску, недвижимость не теряет в цене.
            <br />
            Единственный риск — увеличение срока реализации.{' '}
          </p>
          <div className={css.items}>
            <div className={css.item}>
              <div className={cn(css.itemRound, css.yellow)}></div>
              <div className={css.itemTitle}>Портфель Estate Invest</div>
            </div>
            <div className={css.item}>
              <div className={cn(css.itemRound, css.blue)}></div>
              <div className={css.itemTitle}>ГосОблигации</div>
            </div>
            <div className={css.item}>
              <div className={cn(css.itemRound, css.red)}></div>
              <div className={css.itemTitle}>Индекс IMOEX</div>
            </div>
          </div>
          <div className={css.picPlace}>
            <img className={css.bigPic} src="/images/landing/chart-big.png" alt="" width="1120" height="444" />
            <img className={css.smallPic} src="/images/landing/chart-small.png" alt="" width="328" height="444" />
          </div>
          <div className={css.buttonPlace}>
            <Button1 onClick={() => scrollToHash('#form')}>Начать инвестировать</Button1>
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.risk)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Как мы снижаем риск</h1>
          <div className={css.items}>
            {[
              {
                title: 'Анализ',
                desc: 'Решение о входе в объект принимается после трёх этапов глубокого анализа. Команда аналитиков проверяет сотни объектов, уточняет и прогнозирует ситуацию на рынке с учётом десятков факторов',
                IconHere: Risk1,
              },
              {
                title: 'Диверсификация',
                desc: 'Каждый месяц предлагаем для инвестирования 2–3 объекта, прошедшие жёсткий отбор. Вы можете равномерно добавлять в свой портфель активы разной направленности: коммерческие помещения, дома, квартиры, земли, автомобили и т.д.',
                IconHere: Risk2,
              },
              {
                title: 'Адаптация стратегий',
                desc: 'Ещё до входа в объект мы уже имеем минимум три стратегии его реализации. В случае изменения рынка мы гибко реагируем и адаптируем стратегии реализации, благодаря 8 летнему опыту работы в этой области',
                IconHere: Risk3,
              },
              {
                title: 'Полный цикл',
                desc: 'Мы собираем инвестиции, выкупаем объект и реализуем его самостоятельно, выплачивая прибыль инвесторам',
                IconHere: Risk4,
              },
            ].map(({ title, desc, IconHere }, i) => (
              <div key={i} className={css.item}>
                <div className={css.itemInner}>
                  <IconHere className={css.itemIcon} />
                  <div className={css.itemTitle}>{title}</div>
                  <div className={css.itemDesc}>{desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.princip)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Принципы работы</h1>
          <div className={css.items}>
            {[
              {
                title: (
                  <>
                    Основа проекта
                    <br />— краудфандинг
                  </>
                ),
                desc: 'Привлечение капитала от широкой аудитории инвесторов позволяет иметь в портфеле объекты, которые невозможно проинвестировать в одиночку',
                picSrc: '/images/landing/princip-1-pic.png',
              },
              {
                title: 'Инвестиционный договор',
                desc: 'Для начала работы мы подписываем с вами инвестиционный договор',
                picSrc: '/images/landing/princip-2-pic.png',
              },
              {
                title: 'Диверсификация портфеля и принцип сложного процента',
                desc: 'Благодаря краудфандингу, вы можете распределить свои средства в десятки активов, что обезопасит от затяжной реализации только одного объекта',
                picSrc: '/images/landing/princip-3-pic.png',
              },
            ].map(({ title, desc, picSrc }, i) => (
              <div key={i} className={css.item}>
                <div className={css.itemInner}>
                  <div className={css.itemPic}>
                    <img src={picSrc} alt="" width="240" height="240" />
                  </div>
                  <div className={css.itemTitle}>{title}</div>
                  <div className={css.itemDesc}>{desc}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.comfort)}>
        <div className={css.wrapper}>
          <div className={css.panel}>
            <div className={css.text}>
              <h1 className={css.title}>Удобная платформа для управления капиталом</h1>
              <p className={css.desc}>
                Все активы вашего портфеля всегда в быстром доступе. Операции по счету, распределение средств по
                объектам, а также объекты, доступные для инвестирования, вы найдете в нашем приложении
              </p>
            </div>
            <div className={css.iphonePlace}>
              <img className={css.iphone} src="/images/landing/iphone.png" alt="" width="548" height="523" />
            </div>
          </div>
        </div>
      </div>
      <div id="form" className={cn(css.section, css.form)}>
        <div className={css.wrapper}>
          <div className={css.panelOuter}>
            <img className={css.handLeft} src="/images/landing/hand-left.jpg" alt="" width="582" height="487" />
            <img className={css.handRight} src="/images/landing/hand-right.jpg" alt="" width="582" height="487" />
            <div className={css.panel}>
              <h1 className={css.sectionTitle}>Регистрация в Estate Invest</h1>
              <p className={css.sectionDesc}>
                Присоединяйтесь в инвестиционную команду Estate Invest и начните стабильно инвестировать в недвижимость
                с доходностью от 30% годовых
              </p>
              <div className={css.formOuter}>
                <MainForm referralName={referralName} referralCode={referralCode} />
                <p className={css.rules}>
                  Нажимая «Зарегистрироваться», вы соглашаетесь{' '}
                  <Link to={getDocsTermsRoute()}>с условиями обработки персональных данных</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.more)}>
        <div className={css.wrapper}>
          <h1 className={css.sectionTitle}>Еще больше информации</h1>
          <div className={css.items}>
            <div className={css.itemOuter}>
              <div className={css.item}>
                <div className={css.itemTitle}>Посетите наш блог</div>
                <div className={css.itemDesc}>
                  Посетите наш публичный канал об инвестициях в недвижимость, где мы пишем наши мысли, новости
                  и результаты!
                </div>
                <div className={css.itemButtonPlace}>
                  <Button1
                    href="https://t.me/russianestateincome"
                    target="_blank"
                    color="white"
                    icon="telegram1"
                    className={css.itemButton1}
                  >
                    Перейти в канал
                  </Button1>
                </div>
              </div>
            </div>
            <div className={css.itemOuter}>
              <div className={css.item}>
                <div className={css.itemTitle}>Задайте вопрос</div>
                <div className={css.itemDesc}>
                  Если не хватило информации, можете задать вопрос в чат-бот. С вами свяжется персональный менеджер
                  и ответит на все вопросы.
                </div>
                <div className={css.itemButtonPlace}>
                  <Button1
                    href="https://t.me/Estate_invest_RF_bot"
                    color="white"
                    icon="question"
                    className={css.itemButton1}
                  >
                    Задать вопрос
                  </Button1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(css.section, css.footer)}>
        <div className={css.wrapper}>
          <Link to={'#header'} className={css.logo}>
            <LogoGray />
          </Link>
          <div className={css.navMenu}>
            {/* <Link to={getDocsPolicyRoute()} className={css.navMenuLink}>
              Политика конфиденциальности
            </Link> */}
            <Link to={getCompanyResultsRoute()} className={css.navMenuLink}>
              Наши результаты
            </Link>
            <Link to={getDocsTermsRoute()} className={css.navMenuLink}>
              Условия обработки персональных данных
            </Link>
            {/* <Link to={getDocsContractRoute()} className={css.navMenuLink}>
              Инвестиционный договор
            </Link> */}
          </div>
          <div className={css.copyright}>
            © {new Date().getFullYear()} ИП Мудров Иван Юрьевич ИНН 590610248187 ОГРН 304590632000277
          </div>
        </div>
      </div>
      <ScrollToHash elOffset={30} pageOffset={60} />
    </div>
  )
}

const GetPropertiesWrapper = (props) => {
  const queryResult = useQuery('getProperties', {
    fetchPolicy: 'network-only',
  })

  return (
    <PageWrapper queryResult={queryResult}>
      {({ referralName }) => (
        <PromoPage
          properties={queryResult.properties.filter((property) => property.status === 'foundrising')}
          {...props}
        />
      )}
    </PageWrapper>
  )
}

const GetReferralNameWrapper = (props) => {
  const location = useLocation()
  const parsedLocationSearch = parseLocationSearch(location.search)
  const referralCode = parsedLocationSearch.r
  const queryResult = useQuery('getReferralNameByCode', {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        referralCode,
      },
    },
    skip: !referralCode,
  })

  return (
    <PageWrapper queryResult={queryResult}>
      {({ referralName }) => (
        <GetPropertiesWrapper referralName={referralName || ''} referralCode={referralCode} {...props} />
      )}
    </PageWrapper>
  )
}

export default GetReferralNameWrapper
